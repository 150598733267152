import {
  LOGIN,
  START_LOADING,
  END_LOADING,
  LOGOUT,
  DOWNLOADEXCEL,
  CLEAR_DATA,
  UNSUCCESSFUL,
  EXPORT_RECORDS,
} from "../constants"

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  userData: {},
  downloadData: [],
  count: {},
  exportRecords: [],
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case DOWNLOADEXCEL:
      return {
        ...state,
        downloadData: action.payload,
      }
    case UNSUCCESSFUL:
      console.log(action.payload)
      return {
        ...state,
        count: action.payload,
      }
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload,
      }

    case CLEAR_DATA:
      return {
        ...state,
        downloadData: [],
        count: {},
      }
    case EXPORT_RECORDS:
      return {
        ...state,
        exportRecords: [],
      }
    case LOGOUT:
      return initialState

    default:
      return state
  }
}

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material"
import {makeStyles} from "@mui/styles"
import {Visibility, VisibilityOff, AccountCircle} from "@mui/icons-material"
import {Link} from "react-router-dom"
import React, {useState, useEffect} from "react"
import {useSelector, useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import {getDashboardData, login} from "../redux/actions/auth"
import {validateEmail} from "../utilityFunctions/utilityFunction"
import useInput from "../hooks/use-input"
import logo from "../assets/switchtoenaira.jpg"

const useStyles = makeStyles(theme => ({
  container: {
    background: "#c4c4c4",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: " 100%",
    minHeight: "100vh",
    objectFit: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center ",
  },
  wrapper: {
    marginTop: "6em",
    width: "800px",
    background: "#fff",
    height: "100%",
    padding: "2em",
    borderRadius: "5px",
  },
  Header: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "2em",
  },
  MainHeader: {
    fontSize: "24px !important",
    marginBottom: "10px !important",
    color: "#1A1A1A",
  },
  SubHeader: {
    fontSize: "16px !important",
    color: "#777777",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  formControl1: {
    marginBottom: "20px !important",
    width: "100%",
  },
  formLabels: {
    color: "#1A1A1A",
    marginBottom: "5px",
  },
  button: {
    backgroundColor: "#555658 !important",
    textTransform: "capitalize",
  },
}))
const Login = () => {
  const {
    value: emailInput,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    isValid: emailIsValid,
  } = useInput(email => validateEmail(email))
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const {isLoading, message} = useSelector(state => state.authReducer)
  let formIsValid = false
  // if (emailIsValid && password.length > 7) {
  //   formIsValid = true
  // }
  const handleSubmit = () => {
    let payload = {}
    payload["user_id"] = emailInput
    payload["password"] = password
    dispatch(login(payload))
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {}, [formIsValid])

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Box className={classes.Header}>
          <Typography className={classes.MainHeader}>Log in</Typography>
          {/* <Typography className={classes.SubHeader}>
            If you have no account,{" "}
            <Link to="/register">
              {" "}
              <span style={{color: "blue", cursor: "pointer"}}>Sign Up</span>
            </Link>
          </Typography> */}
        </Box>
        <Box className={classes.content}>
          <FormControl className={classes.formControl1}>
            <label className={classes.formLabels}>User ID</label>
            <TextField
              variant="outlined"
              type="email"
              size="small"
              placeholder="Type your user ID here"
              name="email"
              value={emailInput}
              // onChange={e => setEmail(e.target.value)}
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {/* {emailHasError && (
              <Typography sx={{color: "red"}}>Wrong email format!</Typography>
            )} */}
          </FormControl>
          <FormControl className={classes.formControl1}>
            <label className={classes.formLabels}>Password</label>
            <TextField
              variant="outlined"
              type={`${showPassword ? "text" : "password"}`}
              size="small"
              placeholder="Type your password here"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={handleClickShowPassword}
                    sx={{cursor: "pointer"}}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Button
            variant="contained"
            className={classes.buttton}
            style={{
              background: "#01a717",
              textTransform: "capitalize",
              py: "10px",
              fontSize: "16px",
              color: "#fff",
            }}
            // disabled={!formIsValid}
            type="submit"
            onClick={handleSubmit}
          >
            {isLoading ? (
              <CircularProgress style={{color: "#fff "}} />
            ) : (
              "Log in"
            )}
          </Button>
          <Typography>{message && message}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Login

import React from "react"
import NavBar from "../components/NavBar"
import TableComponent from "../components/TableComponent"

const Dashbord = () => {
  return (
    <>
      <TableComponent />
    </>
  )
}

export default Dashbord

import {Navigate, Outlet} from "react-router-dom"
import {useSelector} from "react-redux"

const useAuth = () => {
  const {isAuthenticated} = useSelector(state => state.authReducer)

  if (isAuthenticated) {
    return true
  } else {
    return false
  }
}

const PublicRoutes = props => {
  const auth = useAuth()

  return auth ? <Navigate to="/dashboard" /> : <Outlet />
}

export default PublicRoutes

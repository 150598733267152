export const validateEmail = email => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateName = name => name.trim() !== ""

export const handleErrorMsg = err => {
  console.log(err, "utit")
  if (err?.errors?.length > 0) {
    const msg = err.errors.map(
      error => `${error?.errorMessage || error?.errorCode}`
    )

    return msg.toString()
  }
  return err
}
export const convertToString = obj => {
  for (let x in obj) {
    if (typeof obj[x] !== "string") {
      obj[x] = String(obj[x])
    }
  }
  return obj
}

export const formatPhoneNo = phoneNo => {
  //console.log(phoneNo)
  let newNum
  const num = phoneNo?.replace(/\s/g, "")
  if (num?.charAt(0) !== "0") {
    newNum = "0" + phoneNo
    return newNum?.slice(0, 11)
  } else if (num?.charAt(0) === "0") {
    return num?.slice(0, 11)
  }
}

export const convResponse = res => {
  let objArr = res.map(obj => {
    let newObj = {}
    //  "PHONE"=obj.uidType
    newObj["Tier"] = obj.tier
    newObj["PHONE NO"] = obj.phone
    newObj["PHONE NO"] = obj.uid
    newObj["LAST NAME"] = obj.lastName
    newObj["FIRST NAME"] = obj.firstName
    newObj["MIDDLE NAME"] = obj.middleName
    newObj["ADDRESS"] = obj.address
    newObj["COUNTRY OF BIRTH"] = obj.countryOfBirth
    newObj["DATE OF BIRTH"] = obj.dateOfBirth
    newObj["POSTAL CODE"] = obj.postalCode
    newObj["REFERRAL CODE"] = obj.referralCode
    newObj["STATE ADDRESS"] = obj.state
    newObj["COUNTRY OF RESIDENCE"] = obj.countryOfResidence
    newObj["CITY"] = obj.city
    newObj["LGA ADDRESS"] = obj.lga
    newObj["STATE OF ORIGIN"] = obj.stateOfOrigin
    newObj["TITLE"] = obj.title
    newObj["CUSTOMER RISK RATING"] = obj.customerRiskRating
    newObj["REASON"] = obj.reason
    newObj["CHANNEL"] = obj.channel
    newObj["NUBAN"] = obj.nuban

    return newObj
  })
  return objArr
}

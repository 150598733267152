import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import React, {useState, useEffect} from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import MaterialTable from "material-table"
import {exportRecords, transactionHistory} from "../redux/actions/createUser"
import {useDispatch} from "react-redux"
import XLSX from "xlsx"
import {CSVLink} from "react-csv"
import {convResponse} from "../utilityFunctions/utilityFunction"
import Pagination from "@mui/material/Pagination"
import {useDebounce} from "../hooks/useDebounce"
import Swal from "sweetalert2"

const Records = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [csv, setCsv] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [data, setData] = useState([])
  const clickRef = React.useRef(null)
  const [page, setPage] = React.useState(1)
  const [inputValue, setInputValue] = useState("")
  const [sortCriteria, setSortCriteria] = useState("all")
  const [status, setStatus] = useState("")
  const debouncedValue = useDebounce(inputValue, 300)
  const [start, setStart] = useState("")
  const [end, setEnd] = useState("")
  const [dateData, setDateData] = useState({})

  useEffect(() => {
    let data = {}
    setLoading(true)

    data.start_date = start
    data.end_date = end

    if (start != "" && end != "") {
      console.log(start, end)
      let startDate = Date.parse(start)
      let endDate = Date.parse(end)
      if (startDate > endDate) {
        console.log("true")
        Swal.fire({
          icon: "error",
          title: "Invalid Date Range",
          text: "Please input a valid date range and try again",
        })
        setLoading(false)
        return
      } else {
        setDateData(data)
        dispatch(
          transactionHistory(
            data,
            sortCriteria,
            debouncedValue,
            page,
            () => setLoading(false),
            res => setData(res.data)
          )
        )
      }
    } else {
      setDateData(data)
      dispatch(
        transactionHistory(
          data,
          sortCriteria,
          debouncedValue,
          page,
          () => setLoading(false),
          res => setData(res.data)
        )
      )
    }
  }, [page, debouncedValue, sortCriteria, end])
  // console.log(data)
  const handleExport = () => {
    setLoading1(true)
    dispatch(
      exportRecords(
        dateData,
        sortCriteria,
        () => setLoading1(false),

        res => {
          if (res.success) {
            setCsv(true)
            // clickRef.current.link.click()
            const newData = convResponse(res.data)
            // console.log(newData, "new")
            setCsvData(newData)
          }
        }
      )
    )
  }
  return (
    <div className="wrapper">
      <div className="App">
        <h1 align="center">GoGetItInvestments</h1>
        {/* <h4 align="center">Import Data from Excel</h4> */}
        {/* <input type="file" onChange={importExcel} /> */}

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {!csv && (
              <Button
                variant="outlined"
                component="span"
                className="btn"
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                }}
                onClick={handleExport}
              >
                {loading1 ? "Downloading..." : "Download"}
              </Button>
            )}
            {csv && (
              <Button
                variant="outlined"
                component="span"
                className="btn"
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                }}
              >
                <CSVLink
                  data={csvData}
                  style={{color: "#FFFF"}}
                  filename={"records.csv"}
                  ref={clickRef}
                >
                  Export {sortCriteria}
                </CSVLink>
              </Button>
            )}
          </div>
          <div>
            <TextField
              id="outlined-basic"
              // label="Outlined"
              placeholder="Search"
              variant="outlined"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortCriteria}
              label="Age"
              onChange={e => setSortCriteria(e.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"successful"}>Successful Transactions</MenuItem>
              <MenuItem value={"failed"}>Failed Transactions</MenuItem>
            </Select>
            <TextField
              id="date"
              label="Start date"
              type="date"
              inputFormat="YYYY/MM/DD"
              // sx={{width: 220}}
              value={start}
              onChange={e => setStart(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="date"
              label="End date"
              type="date"
              inputFormat="yyyy/MM/dd"
              value={end}
              onChange={e => setEnd(e.target.value)}
              // sx={{width: 220}}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>FIRST NAME</TableCell>
                <TableCell>LAST NAME</TableCell>
                <TableCell>MIDDLE NAME</TableCell>
                <TableCell>PHONE NO</TableCell>
                <TableCell>TITLE</TableCell>
                <TableCell>POSTAL CODE</TableCell>
                <TableCell>ADDRESS</TableCell>
                <TableCell>CITY</TableCell>
                <TableCell>LGA ADDRESS</TableCell>
                <TableCell>STATE ADDRESS</TableCell>
                <TableCell>COUNTRY OF RESIDENCE</TableCell>
                <TableCell>DATE OF BIRTH</TableCell>
                <TableCell>COUNTRY OF BIRTH</TableCell>
                <TableCell>STATE OF ORIGIN</TableCell>
                <TableCell>REFERRAL CODE</TableCell>
                <TableCell>NUBAN</TableCell>
                <TableCell>REASON</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress style={{color: "black"}} />
                </div>
              ) : data?.data?.length > 0 ? (
                data?.data?.map((row, i) => (
                  <TableRow
                    key={row.id}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    <TableCell component="th" scope="row">
                      {i + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.firstName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row?.lastName}
                    </TableCell>
                    <TableCell>{row?.middleName}</TableCell>
                    <TableCell>{row?.phone}</TableCell>
                    <TableCell>{row?.title}</TableCell>
                    <TableCell>{row?.postalCode}</TableCell>
                    <TableCell>{row?.address}</TableCell>
                    <TableCell>{row?.city}</TableCell>
                    <TableCell>{row?.lga}</TableCell>
                    <TableCell>{row?.state}</TableCell>
                    <TableCell>{row?.countryOfResidence}</TableCell>
                    <TableCell>{row?.dateOfBirth}</TableCell>
                    <TableCell>{row?.countryOfBirth}</TableCell>
                    <TableCell>{row?.stateOfOrigin}</TableCell>
                    <TableCell>{row?.referralCode}</TableCell>
                    <TableCell>{row?.nuban ? row.nuban : "N/A"}</TableCell>
                    {row?.reason ? row.reason.slice(0, 20) : "N/A"}
                  </TableRow>
                ))
              ) : (
                <div>No Data Available</div>
              )}
            </TableBody>
          </Table>
          <Paper
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2em",
            }}
          >
            <Pagination
              count={data?.total}
              page={page}
              onChange={(e, value) => setPage(value)}
            />
          </Paper>
        </TableContainer>
      </div>
    </div>
  )
}

export default Records

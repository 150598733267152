export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const LOGIN = "LOGIN"
export const REGISTER = "REGISTER"
export const LOGOUT = "LOGOUT"
export const MESSAGE = "MESSAGE"
export const DETAILS = "DETAILS"
export const ITEMSLIST = "ITEMSLIST"
export const SINGLEITEM = "SINGLEITEM"
export const DOWNLOADEXCEL = "DOWNLOADEXCEL"
export const UNSUCCESSFUL = "UNSUCCESSFUL"
export const CLEAR_DATA = "CLEAR_DATA"
export const EXPORT_RECORDS = "EXPORT_RECORDS"

import {Navigate, Outlet} from "react-router-dom"
import {useSelector} from "react-redux"

const useAuth = () => {
  const {isAuthenticated} = useSelector(state => state.authReducer)
  if (isAuthenticated) {
    return true
  } else {
    return false
  }
}

const ProtectedRoute = props => {
  const auth = useAuth()

  return auth ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoute

import callApi from "../../service/apiRequest"
import axios from "axios"
import {LOGIN, START_LOADING, END_LOADING, LOGOUT} from "../constants"
import Swal from "sweetalert2"

export const login = (data) => async dispatch => {
console.log('login block')
  dispatch({type: START_LOADING})
  try {
    const res = await callApi(
      "/auth/login",
      data,
      "POST",
      {
        headers: data,
      },
      true
    )
    if (res.status == "200") {
      dispatch({
        type: LOGIN,
        payload: res,
        cred:data

      })
      localStorage.setItem("token", res.token)
      localStorage.setItem('expire_at', Date.now()+ 9 *1000*60)
      Swal.fire({
        icon: "success",
        text: "Login Successful",
      })
      // navigate("/dashboard")
    } else {
      Swal.fire({
        icon: "error",
        text: "Login Failed",
      })
    }
  } catch (error) {
    console.log('login error')
    Swal.fire({
      icon: "error",
      text: `${error.response.data.error}`,
    })
  } finally {
    dispatch({type: END_LOADING})
  }
}

export const LogoutAccount = () => async dispatch => {
  localStorage.clear()
  dispatch({
    type: LOGOUT,
  })
}

import {combineReducers} from "redux"
import {authReducer} from "./reducer/auth"
import {persistReducer} from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  storage,
  // whitelist:[
  //   'authReducer'
  // ]
}
const rootReducer = combineReducers({
  authReducer,
})

export default persistReducer(persistConfig, rootReducer)

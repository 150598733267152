import React, {useState} from "react"
import {Button, Menu, MenuItem, Fade, Box, Typography} from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import {useDispatch, useSelector} from "react-redux"
import {LogoutAccount} from "../redux/actions/auth"
import UploadExcel from "./UploadExcel"
import {Link} from "react-router-dom"

export default function NavBar() {
  const dispatch = useDispatch()
  const {userData} = useSelector(state => state.authReducer)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openUpload, setOpenUpload] = useState(false)
  const [rows, setRows] = useState()
  const [columns, setColumns] = useState()
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUpload = () => setOpenUpload(!openUpload)

  const fileHandler = event => {}
  return (
    <Box
      sx={{
        p: "1em 2em",
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #ccc",
      }}
    >
      <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{textTransform: "capitalize", color: "#1A1A1A"}}
        >
          Profile
          <ArrowDropDownIcon />
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <Link to="dashboard" style={{color: "black"}}>
            <MenuItem>Home</MenuItem>
          </Link>
          <Link to="dashboard/records" style={{color: "black"}}>
            <MenuItem>All Records</MenuItem>
          </Link>
          <MenuItem
            sx={{color: "red"}}
            onClick={() => dispatch(LogoutAccount())}
          >
            Logout
          </MenuItem>
        </Menu>
        {/* n */}
      </div>
      {/* <UploadExcel open={openUpload} close={() => setOpenUpload(false)} /> */}
      {/* <OutTable
        data={rows}
        columns={columns}
        tableClassName="ExcelTable2007"
        tableHeaderRowClass="heading"
      /> */}
    </Box>
  )
}

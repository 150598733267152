import callApi from "../../service/apiRequest"
import axios from "axios"
import {
  LOGIN,
  START_LOADING,
  END_LOADING,
  LOGOUT,
  DOWNLOADEXCEL,
  UNSUCCESSFUL,
  CLEAR_DATA,
  EXPORT_RECORDS,
} from "../constants"
import Swal from "sweetalert2"
import {
  handleErrorMsg,
  convertToString,
} from "../../utilityFunctions/utilityFunction"
import {login} from "./auth"

let token = {
  token: null,
  expire_at: 0,
}

// console.log(Date.now()) // 12:02
// console.log(Date.now() + 9 * 1000 * 60) // 12:11

async function getToken() {
  // await called auth endpoint - result;

  var config = {
    method: "post",
    url: "https://caml1.enaira.gov.ng/camluam/api/v1/auth/login",
    // url: "https://camltest.azurewebsites.net/camluam/api/v1/auth/login",
    headers: {
      user_id: "gogetitinvestments",
      password:
        "QDIwMjNHb2dldGl0SW52ZXN0bWVudEx0ZGVOYWlyYUludGVncmF0aW9uUHJvamVjdA$==",
      // password: "QWNjZXNzUEBzc3dvcmQxMjM$=",
    },
  }

  try {
    const res = await axios(config)
    localStorage.setItem("token", res?.data?.token)
    localStorage.setItem("expire_at", Date.now() + 9 * 1000 * 60)
    token = {
      token: res.data.token,
      expire_at: Date.now() + 9 * 1000 * 60,
    }
  } catch (error) {
    console.log(error)
  }
}

async function tokenIsValid() {
  if (Number(localStorage.getItem("expire_at")) <= Date.now()) {
    await getToken()
  }
}

//https://camltest.azurewebsites.net/camlcompliance/api/v1/
//https://caml2.enaira.gov.ng/camlcompliance/api/v1/
export const createUser = (newData, payload, url, cb) => async dispatch => {
  //rest operator to remove tableData from the payload
  const data = newData.map(({tableData, ...rest}) => rest)
  dispatch({type: START_LOADING})
  let i, len
  const count = {
    successful: 0,
    unsuccessful: 0,
  }

  for (i = 0, len = data.length; i < len; i++) {
    // await tokenIsValid()
    try {
      const res = await axios.post(
        `https://gogetitbackend.gogetitenaira.ng/createconsumerdetails`,
        // `https://caml2.enaira.gov.ng/camlcompliance/api/v1/${url}`,

        convertToString(data[i]),
        true
      )
      if (res.status == "200") {
        console.log(res)
        // successful.push({...data[i], nuban: res?.data?.nuban})
        count.successful = count.successful + 1

        Swal.fire({
          icon: "success",
          title: "Successful",
          text: res?.data?.data?.message
            ? res?.data?.data?.message
            : "Consumer details was successfully sent for wallet creation",
        })
      } else {
        Swal.fire({
          icon: "error",
          text: "An error occured",
        })
      }
    } catch (error) {
      count.unsuccessful = count.unsuccessful + 1
      dispatch({
        type: UNSUCCESSFUL,
        payload: count,
      })

      // unsuccessful.push({
      //   ...data[i],
      //   Reason: handleErrorMsg(error?.response?.data?.message),
      // })

      Swal.fire({
        icon: "error",
        text: error?.response?.data
          ? handleErrorMsg(error?.response?.data?.message)
          : "Something went wrong",
      })
    } finally {
      cb()
      dispatch({
        type: UNSUCCESSFUL,
        payload: count,
      })
    }
  }
}
export const transactionHistory =
  (date, sortCriteria, nbn, page, cb, callback) => async dispatch => {
    //  /history?status=failed&nuban=123456789012
    const nuban = nbn ? `&nuban=${nbn}` : ""
    const newdate = date.end_date
      ? `&start_date=${date.start_date}&end_date=${date.end_date}`
      : ""
    try {
      const res = await axios.get(
        `https://gogetitbackend.gogetitenaira.ng/history?page=${page}&status=${sortCriteria}${nuban}${newdate}`
        // `https://caml2.enaira.gov.ng/camlcompliance/api/v1/${url}`,
      )
      const {data} = res
      callback(data)
    } catch (error) {
      console.log(error)

      Swal.fire({
        icon: "error",
        text: error?.response?.data
          ? handleErrorMsg(error?.response?.data?.message)
          : "Something went wrong",
      })
    } finally {
      cb()
    }
  }
export const exportRecords = (date, sort, cb, callback) => async dispatch => {
  //  /history?status=failed&nuban=123456789012
  const newdate = date.end_date
    ? `&start_date=${date.start_date}&end_date=${date.end_date}`
    : ""
  try {
    const res = await axios.get(
      `https://gogetitbackend.gogetitenaira.ng/history?paginated=no&status=${sort}${newdate}`
      // `https://caml2.enaira.gov.ng/camlcompliance/api/v1/${url}`,
    )
    const {data} = res
    callback(data)
    console.log(data)
  } catch (error) {
    // Swal.fire({
    //   icon: "error",
    //   text: error?.response?.data
    //     ? handleErrorMsg(error?.response?.data?.message)
    //     : "Something went wrong",
    // })
  } finally {
    cb()
  }
}

export const clearData = () => async dispatch => {
  dispatch({
    type: CLEAR_DATA,
  })
}

import "./App.css"
import {Route, Routes, Navigate} from "react-router-dom"
import Login from "./auth/Login"
import Dashboard from "./pages/Dashboard"
import ProtectedRoute from "./auth/ProtectedRoute"
import {useSelector} from "react-redux"
import PublicRoutes from "./auth/PrivateRoutes"
import Records from "./components/Records"
import NavBar from "./components/NavBar"

function App() {
  const {isAuthenticated} = useSelector(state => state.authReducer)
  return (
    <>
      {isAuthenticated && <NavBar />}
      <Routes>
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/records" element={<Records />} />
        </Route>
        <Route path="" element={<PublicRoutes />}>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </>
  )
}

export default App

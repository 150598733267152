import React, {useState, useEffect} from "react"
import "../App.css"
import MaterialTable from "material-table"
import XLSX from "xlsx"
import {useSelector, useDispatch} from "react-redux"
import {Button, CircularProgress, Paper} from "@mui/material"
import {clearData, createUser} from "../redux/actions/createUser"
import {DOWNLOADEXCEL} from "../redux/constants"
import {
  convertToString,
  formatPhoneNo,
} from "../utilityFunctions/utilityFunction"
import callApi from "../service/apiRequest"

const EXTENSIONS = ["xlsx", "xls", "csv"]
function App() {
  const [colDefs, setColDefs] = useState()
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const {userData, downloadData, count} = useSelector(
    state => state.authReducer
  )
  const [isMerchant, setisMerchant] = useState(false)
  const [isConsumer, setisConsumer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [downloads, setDownloads] = useState([])
  const [total, setTotal] = useState("")
  const getExention = file => {
    const parts = file.name.split(".")
    const extension = parts[parts.length - 1]
    return EXTENSIONS.includes(extension) // return boolean
  }

  const convertToJson = (headers, data) => {
    const rows = []
    data.forEach(row => {
      let rowData = {}
      row.forEach((element, index) => {
        rowData[headers[index]] = element
      })
      rows.push(rowData)
    })
    return rows
  }

  const importExcel = e => {
    setTotal("")
    dispatch(clearData())
    const file = e.target.files[0]
    const reader = new FileReader()
    const readOpts = {
      // <--- need these settings in readFile options
      cellDates: true,
      type: "binary",
    }
    setName(file?.name)
    reader.onload = event => {
      //parse data

      const bstr = event.target.result
      const workBook = XLSX.read(bstr, {
        type: "binary",
        // cellDates: true,
      })

      //get first sheet
      const workSheetName = workBook.SheetNames[0]
      const workSheet = workBook.Sheets[workSheetName]
      //convert to array
      const fileData = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
        raw: false,
      })
      const headers = fileData[0]
      const heads = headers.map(head => ({title: head, field: head}))
      setColDefs(heads)

      //removing header
      fileData.splice(0, 1)

      setData(convertToJson(headers, fileData))
    }

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file)
      } else {
        alert("Invalid file input, Select Excel, CSV file")
      }
    } else {
      setData([])
      setColDefs([])
    }
  }

  const handleFetch = async () => {
    setLoading(true)
    let payload = {}
    payload.userId = userData.data.userid
    // setLoading(true)
    let successful = []
    const objArr = data?.map(item => {
      //    delete item.tableData
      if (Object.keys(item).length > 1) {
        return item
      }
    })
    const newObjArr = objArr.filter(Boolean)
    let objArr1 = newObjArr.map(item => {
      let newObj = {}
      for (let k in item) {
        const val = k.replace(/"/g, "").trim()
        newObj[val] = item[k]
      }
      let obj = {}
      if (isConsumer) {
        obj.uidType = "PHONE"
        obj.tier = "0"
        obj.phone = formatPhoneNo(newObj["PHONE NO"])
        obj.uid = newObj["PHONE NO"]
        obj.lastName = newObj["LAST NAME"]
        obj.firstName = newObj["FIRST NAME"]
        obj.middleName = newObj["MIDDLE NAME"]
        obj.address = newObj["ADDRESS"]
        obj.countryOfBirth = "NG"
        obj.dateOfBirth = newObj["DATE OF BIRTH"]
        obj.postalCode = newObj["POSTAL CODE"]
        obj.referralCode = newObj["REFERRAL CODE"]
        obj.state = newObj["STATE ADDRESS"]
        obj.countryOfResidence = "NG"
        obj.city = newObj["CITY"]
        obj.lga = newObj["LGA ADDRESS"]
        obj.stateOfOrigin = newObj["STATE OF ORIGIN"]
        obj.title = newObj["TITLE"]
        obj.channel = "FI"
        obj.customerRiskRating = "0"
        obj.password = "123456789123"
      }
      if (isMerchant) {
        obj.tier = "2"
        obj.uidType = "TIN"
        obj.uid = newObj["TIN"]
        obj.businessName = newObj["Business Name"]
        obj.dirBvn = newObj["Director BVN"]
        obj.dirFirstName = newObj["Director First Name"]
        obj.dirMiddleName = newObj["Director Middle Name"]
        obj.dirLastName = newObj["Director Last Name"]
        obj.countryOfBirth = newObj["COUNTRY OF BIRTH"]
        obj.dirDateOfBirth = newObj["Director Date Of Birth"]
        obj.phone = newObj["Phone Number"]
        obj.emailId = newObj["Email Address"]
        obj.city = newObj["City"]
        obj.state = newObj["State"]
        obj.lga = newObj["LGA"]
        obj.address = newObj["Address"]
        obj.country = newObj["Country"]
        obj.accountNumber = newObj["Account Number"]
        obj.stateOfOrigin = newObj["State Of Origin"]
        obj.instCode = item["Bank"]
      }
      return obj
    })
    setTotal(objArr1.length)
    const url = isMerchant
      ? "createmerchantdetailsopen"
      : "createconsumerdetailsopen"
    dispatch(createUser(objArr1, payload, url, () => setLoading(false)))
  }

  useEffect(() => {
    setDownloads(downloadData)
  }, [downloadData])

  const downloadXls = () => {
    const workSheet = XLSX.utils.json_to_sheet(downloads)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Successful Records")

    //buffer
    let buffer = XLSX.write(workBook, {bookType: "xlsx", type: "buffer"})

    //binary string
    XLSX.write(workBook, {bookType: "xlsx", type: "binary"})
    //download
    XLSX.writeFile(workBook, name)
  }

  const succesfulPercentage = () => {
    let percentage
    if (total > 0) {
      if (count?.unsuccessful + count?.successful === total) {
        percentage = (count.successful / total) * 100
        return percentage != undefined
          ? "Percentage Success: " + percentage + "%"
          : "Percentage Success: " + 0
      }
    }
  }

  return (
    <div className="wrapper">
      <div className="App">
        <h1 align="center">GoGetItInvestments</h1>
        <h4 align="center">Import Data from Excel</h4>
        {/* <input type="file" onChange={importExcel} /> */}

        <div style={{display: "flex", justifyContent: "space-betweeen"}}>
          <div>
            <label htmlFor="contained-front-file">
              <input
                id="contained-front-file"
                // multiple
                type="file"
                style={{display: "none"}}
                onChange={importExcel}
                onClick={() => {
                  setisMerchant(true)
                  setisConsumer(false)
                }}
                // value={sideView}
              />
              <Button
                variant="outlined"
                component="span"
                className="btn"
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                }}
              >
                Upload Merchant Sheets
              </Button>
              {/* {isMerchant && (
              <Button className="btn" variant="contained" onClick={handleClick}>
                Create Merchant
              </Button>
            )} */}
            </label>
            <label htmlFor="contained-front-file-1">
              <input
                id="contained-front-file-1"
                // multiple
                type="file"
                style={{display: "none"}}
                onChange={importExcel}
                onClick={() => {
                  setisConsumer(true)
                  setisMerchant(false)
                }}
                // value={sideView}
              />
              <Button
                variant="outlined"
                component="span"
                className="btn"
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                  margin: " 0 5px",
                }}
              >
                {loading && <CircularProgress size="small" />} Upload Consumer
                Sheets
              </Button>
            </label>
            {isMerchant && (
              <Button
                className="btn"
                variant="outlined"
                onClick={handleFetch}
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                  marginRight: "5px",
                }}
              >
                {loading ? "Creating..." : "Create Merchant"}
              </Button>
            )}
            {isConsumer && (
              <Button
                className="btn"
                variant="outlined"
                onClick={handleFetch}
                style={{
                  background: "#01a717",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                  marginRight: "5px",
                }}
              >
                {loading ? "Creating..." : "Create Consumer"}
              </Button>
            )}
            {downloadData?.length > 0 ? (
              <Button
                className="btn"
                variant="outlined"
                style={{
                  background: "rgb(46 41 15)",
                  color: "#FFFF ",
                  textTransform: "capitalize ",
                  marginRight: "5px",
                }}
                onClick={downloadXls}
              >
                Download Successful
              </Button>
            ) : (
              ""
            )}
          </div>
        </div>
        <Paper style={{padding: "1em"}}>
          <h3>Total: {total ? total : "0"}</h3>
          <h3>Successful: {count?.successful ? count?.successful : 0}</h3>
          <h3>Unsuccessful: {count?.unsuccessful ? count?.unsuccessful : 0}</h3>
          <h3>{succesfulPercentage()}</h3>
        </Paper>
        <MaterialTable
          title={name}
          data={data}
          columns={colDefs}
          options={{
            search: true,
          }}
        />
      </div>
    </div>
  )
}

export default App

import axios from "axios"
import {string, object, oneOf, bool} from "prop-types"

const base_url = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
  baseURL: "https://caml1.enaira.gov.ng/camluam/api/v1",
})
//"https://caml1.enaira.gov.ng/camluam/api/v1"
//camltest.azurewebsites.net/camluam/api/v1
//https:
export function publicRoute(url, data, method) {
  return new Promise((resolve, reject) => {
    if (method === "POST") {
      return axiosInstance
        .post(url, data)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "GET") {
      return axiosInstance
        .get(url)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "PUT") {
      return axiosInstance
        .put(url, data)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
  })
}

export function privateRoute(url, data, method, config) {
  if (localStorage.getItem("token")) {
    let checkToken = localStorage.getItem("token")
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${checkToken}`,
    }
  }

  return new Promise((resolve, reject) => {
    if (method === "POST") {
      return axiosInstance
        .post(url, data, config)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "PUT") {
      return axiosInstance
        .put(url, data)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "GET") {
      return axiosInstance
        .get(url, config)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "DELETE") {
      return axiosInstance
        .delete(url, config)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
    if (method === "PATCH") {
      return axiosInstance
        .patch(url, data)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    }
  })
}

function callApi(url, data, method, config, token) {
  if (token) {
    return privateRoute(url, data, method, config)
  }
  return publicRoute(url, data, method, config)
}

callApi.propTypes = {
  url: string,
  data: object,
  method: oneOf(["POST", "GET", "PATCH", "PUT", "DELETE"]),
  config: object,
  token: bool,
}

export default callApi
